<template>
  <div class="edit" v-loading="loading">
    <div class="main">
      <yj-progress-edit :statusList="statusList" :currentStatus="currentStatus"></yj-progress-edit> 
        <div class="main-center">
            <div class="main-left">
                <ht-cn-edit-left :infoObj="infoObj"/>
            </div>
            <div class="main-right">
                <ht-cn-edit-right/>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import htCnEditLeft from './HtCnComponent/htCnEditLeft.vue'
import htCnEditRight from './HtCnComponent/htCnEditRight.vue'
export default {
  props: {},
  components:{
      htCnEditLeft,
      htCnEditRight
  },
  created(){
       this.infoObj=this.$route.query
  },
  data() {
    return {
        loading:false,
        infoObj:{},
      statusList: [
        {
          status: '1',
          name: '填写',
        },
        {
          status: '2',
          name: '支付',
        },
        {
          status: '3',
          name: '完成',
        },
      ],
      currentStatus: 1,
    }
  },
  
}
</script>

<style scoped lang="scss">
.edit {
  background-color: #efefef;
  height: 100%;
 
  .main {
      width: 1200px;
      margin: 0 auto;
      /deep/ .pp-main{
          margin: 0px;
          box-sizing: border-box;
          padding-top: 30px;
          padding-bottom: 30px;
        //   margin-top: 30px;
        //   margin-bottom: 30px;
    
      }
      .main-center{
          display: flex;
          background-color: #efefef;
              justify-content: space-between;
          .main-left{
              width: 780px;
          }
          .main-right{
             width: 390px;
          }
      }

  }
}
</style>
